/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */


@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: 'Mulish', sans-serif, 'Ubuntu', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: relative;
}
/* body {
  margin: 0;
  font-family: 'Mulish', sans-serif, 'Ubuntu', sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: transparent;
} */
/* 
.coin-marquee-container {
  display: flex;
  width: calc(70%);
  overflow: hidden;
  white-space: nowrap;
  margin-left: auto;
  margin-right: auto;

}
.coin-marquee-header {
  float: left;
  width: 141px;
}
.coin-marquee-header-signature {
  overflow: hidden;
  width: 141px;
  padding: 12px 16px;
  box-sizing: border-box;
  border-right: 1px solid #eff2f5;
}

.coin-marquee-container__inner {
  position: relative;
  width: 100%;
  background-color: white;
  display: inline-block;
  font-size: 0;
  line-height: 0;
  -webkit-animation-play-state: running;
  animation-play-state: running;
}
.coin-marquee-item {
  position: relative;
  min-width: 220px;
  display: inline-block;
  box-sizing: border-box;
  padding: 0 16px;
}
.coin-marquee-item-inner {
  padding: 16px 0;
  display: flex;
  width: 100%;
  align-items: center;
  text-decoration: none;
  color: #000;
}
.coin-marquee-item__icon {
  margin-right: 8px;
  display: inline-block;
  line-height: 1;
  min-width: 20px;
  max-width: 20px;
  height: 20px;
}
.coin-marquee-item__icon img {
  width: 100%;
  height: 100%;
}
.coin-marquee-item-info {
  width: 100%;
} */
/* 
@media (min-width: 1400px){
.containers, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl {
    max-width: 1320px;
}}
@media (min-width: 1200px){
.containers, .container-lg, .container-md, .container-sm, .container-xl {
    max-width: 1140px;
}}
@media (min-width: 992px){
.containers, .container-lg, .container-md, .container-sm {
    max-width: 960px;
}}
@media (min-width: 768px){
.containers, .container-md, .container-sm {
    max-width: 720px;
}}
@media (min-width: 576px){
.containers, .container-sm {
    max-width: 540px;
}} */
