.navBar-expand {
    max-height: 450px;
    transition: max-height 0.3s ease-in-out;
  }
  
  .navBar-collapse {
    max-height: 68px;
    transition: max-height 0.3s ease-in-out;
  }
  
  .nav-item-show {
    animation: fadeIn 0.3s ease-in-out ;
  }
  
  .nav-item-hide {
    animation: fadeOut 0.5s ease-in-out;
  }
  
  @keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
  }
  
  @keyframes fadeOut {
    0% { opacity: 1; }
    100% { opacity: 0; }
  }