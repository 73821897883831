
/* .navBar {
    display: flex;
    flex-wrap: wrap;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    z-index: 5;
   
} */
a.nav-link {
    color: #fff;
    text-decoration: none;
}
.nav-link {
    font-size: 1.1rem;
    letter-spacing: 1.1px;
    font-weight: 600;
    margin: 0 5px;
    display: block;
    padding: 0.5rem 1rem;
    color: #0d6efd;
    text-decoration: none;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out;
}

.Home {
    overflow: hidden;
    background: #0777cf;
    padding: 200px 0;
    min-height: 45em;
    margin-top: -60px;
    color: #fff;
    background-color: #0093E9;
    /* background-image: linear-gradient(130deg, #0093E9 0%, #0777cf 52%, #0777cf 100%); */
    background: radial-gradient(44.33% 80.51% at 5.74% 26.1%, rgba(20, 153, 249, 0.8) 32.68%, rgba(15, 114, 229, 0) 100%), radial-gradient(54.21% 154.69% at 73.96% 100%, #1499f9 0%, rgba(16, 131, 231, 0.9) 41.91%, rgba(15, 114, 229, 0) 93.71%), #063975;
    background-image: radial-gradient(44.33% 80.51% at 5.74% 26.1%, rgba(20, 153, 249, 0.8) 32.68%, rgba(15, 114, 229, 0) 100%), radial-gradient(54.21% 154.69% at 73.96% 100%, rgb(20, 153, 249) 0%, rgba(16, 131, 231, 0.9) 41.91%, rgba(15, 114, 229, 0) 93.71%), initial;
    background-position-x: initial, initial, initial;
    background-position-y: initial, initial, initial;
    background-size: initial, initial, initial;
    background-attachment: initial, initial, initial;
    background-origin: initial, initial, initial;
    background-clip: initial, initial, initial;
    background-color: rgb(6, 57, 117);
    position: relative;
    z-index: -1;
}

.Home::before {
    content: 'Bitcoin Asset Bitcoin Asset Bitcoin Asset Bitcoin Asset Bitcoin Asset Bitcoin Asset BUY MORE';
    position: absolute;
    width: 100%;
    font-size: 12rem;
    color: #ffffff;
    font-style: italic;
    text-shadow: 8px 5px 4px #000000;
    font-weight: bold;
    opacity: 0.02;
    transform: rotateZ( -18deg);
    line-height: 275px;
    top: -190px;
    left: 0;
    z-index: -1;
}

.Home::after {
    content: '';
    width: 160px;
    height: 55px;
    position: absolute;
    bottom: 13em;
    left: 0;
    z-index: 0;
}

.ro-animate {
    animation: rotate3d 3s ease-in-out infinite;
    position: absolute;
    top: 0;
    right: 0;
    background: transparent;
    height: 100%;
    width: 100%;
    border-radius: 50rem;
    box-shadow: -4px 5px 20px 0px #fff;
}

@keyframes rotate3d {
    0%, 100% {
        transform: rotate3d(1, 1, 6, 45deg);
    }
    25% {
        transform: rotate3d(1, 1, 6, 90deg);
    }
    
    50% {
        transform: rotate3d(3, 5, 6, 45deg);
    }
    75% {
        transform: rotate3d(1, 2, 6, 90deg);
    }
}

.circle {
    border-radius: 100%;
    width: 399px;
    height: 399px;
    background: #ffffff;
    position: absolute;
    top: 0;
    z-index: 0;
    animation: updown 2.4s ease-in-out infinite;
    box-shadow: 0px 0px 7px 2px #00fdff;
}
.image-content img {
    z-index: 1;
    position: relative;
    animation: updown 2.5s ease-in-out infinite;
    transition: 0.5 ease;
    top: 4em;
    max-width: 320px;
    transform: translate3d(0,0,1px);
    -webkit-transform: translate3d(0,0,1px);
}

.updown {
    
    animation: updown 3.4s ease-in-out infinite;
}

@keyframes updown {
    0%, 100% {
        top: 0em;
    }
    50% {
        top: 2em;
    }
}

section.section-space {
    background: url(../assets/img/line.svg);
    background-size: cover;
}

.section-space {
    padding: 150px 0;
    position: relative;
}

.section-space.py-2 img.w-full {
    padding: 2.4rem!important;
    height: 150px;
    object-fit: contain;
    transition: 0.3s ease-out;
}

.section-space.py-2 img.w-full:hover {
    padding: 0 1.4rem!important;
    height: 150px;
    object-fit: contain;
    transition: 0.3s ease-in;
}

.side-el {
    position: absolute;
    left: 0;
    top: 15rem;
    background: url(../assets/img/side-el.png);
    width: 170px;
    height: 500px;
    background-size: contain;
    background-repeat: no-repeat;
    /* animation: outpacity 2s ease infinite; */
}

.side-el.left {
    position: absolute;
    left: initial;
    bottom: 15rem;
    background: url(../assets/img/side-el.png);
    width: 170px;
    height: 500px;
    top: initial;
    right: 0;
    background-size: contain;
    background-repeat: no-repeat;
    transform: rotate(180deg);
    /* animation: outpacity 2.5s ease infinite; */
}

